import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery as useQueryTs } from '@tanstack/react-query';
import gql from 'graphql-tag';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import * as yup from 'yup';
import {
  CheckboxField,
  LoadingIndicator,
  fetchCountryInfoFromIpService,
  getBrowserTimeZone,
} from '../../../common';
import {
  GET_INDUSTRIES_AND_COUNTRIES,
  SEND_REGISTRATION_PAGE_UPDATE,
} from '../../auth/register/api';
import { ControlledSelectField } from './_common/selectField';
import ErrorMessage from '../../../common/fields/common/errorMessage';
import ApiErrorMessage from '../../../common/errorMessage';

const REGISTER = gql`
  mutation Authenticate($input: AuthInput!) {
    authenticate(input: $input)
  }
`;

const StyledInput = styled.input`
  font-family: 'Inter', sans-serif;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.stroke};
  font-size: 1.4rem;
  line-height: 1.2rem;
  padding: 1.3rem 1.5rem;

  transition: background-color 0.333s ease-in-out,
    border-color 0.333s ease-in-out;
  width: 100%;
  color: ${({ theme }) => theme.dark600};
  background-color: ${({ theme }) => theme.white};
  &:hover {
    border-color: ${({ theme }) => theme.stroke};
  }
  &:focus {
    border: 2px solid ${({ theme }) => theme.waterBlue};
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.dark300};
    transition: color 0.333s ease-in-out;
  }

  /* Vertical center placeholder on FireFox */
  ::-moz-placeholder {
    line-height: 2.4rem;
  }

  /* Color Safari password icon */
  ::-webkit-credentials-auto-fill-button {
    background-color: ${({ theme }) => theme.berryBlue};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.dark200};
      border-color: ${({ theme }) => theme.stroke};
      &:hover {
        border-color: ${({ theme }) => theme.stroke};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      background-color: ${({ theme }) => `${theme.grapefruitRed}29`};
      border-color: ${({ theme }) => theme.grapefruitRed};
      color: ${({ theme }) => theme.grapefruitRed};

      &:active,
      :focus {
        border-color: ${({ theme }) => theme.grapefruitRed};
        color: ${({ theme }) => theme.grapefruitRed};
      }
    `}
`;

const ChangeInformation = styled.p`
  color: ${({ theme }) => theme.dark200};
`;

const StyledGoNext = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  color: white;
  border-radius: 99999px;
  border: none;

  z-index: 1;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out,
    0.2s border-color ease-in-out;

  height: 4.8rem;
  padding: 0 2.4rem;

  & p {
    font-size: 1.6rem;
  }

  background: #7890c9;
  background: linear-gradient(135deg, #7890c9 0%, #b19dd0 100%);

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5rem;
    background-color: #7890c9;
    z-index: -2;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5rem;
    background-color: #b19dd0;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover:enabled::before {
    opacity: 1;
  }

  &:active::after {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 1.6rem;
  line-height: 3.6rem;
`;

const About = styled.p`
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;

  margin-bottom: 2rem;
`;

const TermsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: flex-start;

  margin-top: 0.6rem;

  label > div {
    padding-right: 0.5rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 400;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const StyledApiErrorMessage = styled(ApiErrorMessage)`
  margin-top: 2rem;
`;

/**
 *
 * @param {{ register: import('react-hook-form').UseFormRegister , errors: import('react-hook-form').FieldErrors, name: string }} props
 */
const fullRegister = (props) => {
  return {
    ...props.register(props.name),
    error: props.errors[props.name]?.message,
  };
};

/**
 * @typedef {{register: any}} InputFieldProps
 */

/**
 *
 * @param {React.InputHTMLAttributes & InputFieldProps} props
 */

const InputField = (props) => {
  const { errorMessage, ...register } = React.useMemo(() => {
    return {
      errorMessage: props.register.error,
      ...props.register,
    };
  }, [props.register]);

  return (
    <div>
      <StyledInput {...props} {...register} />
      {typeof errorMessage === 'string' && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </div>
  );
};

const validationSchema = (t) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t('common:form.validation.firstName.required')),
    lastName: yup
      .string()
      .required(t('common:form.validation.lastName.required')),
    organisation: yup
      .string()
      .required(t('common:form.validation.organizationName.required')),
    sector: yup
      .object()
      .nullable()
      .shape({ value: yup.string(), label: yup.string() })
      .required(t('common:form.validation.industry.required')),
    termsAndConditions: yup
      .bool()
      .oneOf([true], t('common:form.validation.termsAndConditions.required'))
      .required(),
  });

export const OnBoardingRegistration = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema(t)),
    defaultValues: {
      firstName: decodeURIComponent(searchParams.get('first_name') ?? ''),
      lastName: decodeURIComponent(searchParams.get('last_name') ?? ''),
      organisation: '',
      sector: '',
      termsAndConditions: false,
    },
  });

  const { data: countryInfo } = useQueryTs(
    ['viewer_country_code'],
    async () => {
      return (await fetchCountryInfoFromIpService()) ?? 'unknown';
    }
  );

  const [sendRegistrationPageUpdate, { loading: pageUpdateLoading }] =
    useMutation(SEND_REGISTRATION_PAGE_UPDATE);

  const [registrationMutation, { loading, error }] = useMutation(REGISTER, {
    onCompleted: ({ authenticate }) => {
      sendRegistrationPageUpdate({
        variables: {
          eventUrl: window.location.href,
          eventType: (() => {
            if (searchParams.get('registrationCode')) {
              return 'partner-registration-code';
            }
            if (searchParams.get('memberCode')) {
              return 'member-get-member-code';
            }
            return 'default';
          })(),
          email: decodeURIComponent(searchParams.get('email')),
          country: countryInfo?.country_code?.toLowerCase(),
          ip: countryInfo?.ip,
          agent: window.navigator.userAgent,
        },
      });

      window.location.href = authenticate;
    },
  });

  const { data: sectorOptions, loading: sectorOptionsLoading } = useQuery(
    GET_INDUSTRIES_AND_COUNTRIES
  );

  const selectFieldSectorOptions = React.useMemo(
    () =>
      (sectorOptions?.industries || []).map((industry) => ({
        label: industry.name,
        value: industry.id,
      })),
    [sectorOptions]
  );

  const handleRegistration = React.useCallback(
    (formData) => {
      delete formData.termsAndConditions;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'sign_up',
        sector: formData.sector.label,
      });

      registrationMutation({
        variables: {
          input: {
            ...formData,
            sector: Number(formData.sector.value),
            auth0Id: decodeURIComponent(searchParams.get('userId')),
            timezone: getBrowserTimeZone(),
            memberCode: searchParams.get('memberCode')
              ? decodeURIComponent(searchParams.get('memberCode'))
              : null,
            registrationCode: searchParams.get('registrationCode')
              ? decodeURIComponent(searchParams.get('registrationCode'))
              : '',
            productCode: searchParams.get('productCode')
              ? decodeURIComponent(searchParams.get('productCode'))
              : null,
            productId: searchParams.get('productId')
              ? Number(decodeURIComponent(searchParams.get('productId')))
              : null,
          },
        },
      });
    },
    [registrationMutation, searchParams]
  );

  return (
    <>
      <form onSubmit={handleSubmit(handleRegistration)}>
        <Title>{t('registration:onboard.title')}</Title>
        <About>{t('registration:onboard.about')}</About>
        {searchParams.get('is_social') === 'true' && (
          <ChangeInformation>
            {t('registration:onboard.name.change')}
          </ChangeInformation>
        )}

        <FlexColumn>
          <InputField
            disabled={searchParams.get('is_social') === 'true'}
            placeholder={`${t('registration:onboard.form.firstName')}`}
            register={fullRegister({ name: 'firstName', register, errors })}
          />
          <InputField
            disabled={searchParams.get('is_social') === 'true'}
            placeholder={`${t('registration:onboard.form.lastName')}`}
            register={fullRegister({ name: 'lastName', register, errors })}
          />
          <InputField
            placeholder={`${t('registration:onboard.form.organization')}`}
            register={fullRegister({ name: 'organisation', register, errors })}
          />

          <div>
            <ControlledSelectField
              control={control}
              name="sector"
              options={selectFieldSectorOptions}
              placeholder={t('registration:onboard.form.sector')}
              isLoading={sectorOptionsLoading}
              error={errors?.sector?.message}
            />
            {errors?.sector?.message && (
              <ErrorMessage>{errors.sector.message}</ErrorMessage>
            )}
          </div>
          <div>
            <TermsContainer>
              <CheckboxField
                register={register}
                name="termsAndConditions"
                label={
                  <p>
                    <Trans
                      i18nKey="registration:onboard.termsAndConditions.label"
                      components={{
                        strong_gt: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                          <a
                            href={t(
                              'registration:onboard.termsAndConditions.href'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              borderBottom: '2px solid #75acff',
                              paddingBottom: '0.125rem',
                            }}
                          />
                        ),
                        strong_pp: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                          <a
                            href={t('config:registration.privacyCookiesUrl')}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              borderBottom: '2px solid #75acff',
                              paddingBottom: '0.125rem',
                            }}
                          />
                        ),
                      }}
                    />
                  </p>
                }
              />
            </TermsContainer>
            {errors?.termsAndConditions?.message && (
              <ErrorMessage>{errors.termsAndConditions.message}</ErrorMessage>
            )}
          </div>
        </FlexColumn>
        {error && <StyledApiErrorMessage error={error} />}
        <div style={{ height: '4.8rem', marginTop: '2.6rem' }} />
        <StyledGoNext disabled={loading || pageUpdateLoading} type="submit">
          {loading ? (
            <LoadingIndicator color="white" />
          ) : (
            <p>{t('registration:onboard.submit')}</p>
          )}
        </StyledGoNext>
      </form>
    </>
  );
};
