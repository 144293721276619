import { ApolloProvider } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, from, Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';

import config from '../../config';
import LoadingPage from '../application/loadingPage';
import { useLanguageGetter } from '../../modules/languagePicker/languageGetterProvider';

const logErrorLink = onError(({ graphQLErrors, networkError }) => {
  // When there are graphqlErrors (these are backend errors), log them to the console.
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  // If there's a networkError log it to the console.
  if (networkError) {
    console.log(networkError);
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});

// The default requestLink.
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      try {
        // This handle is what your Query uses to observe handled values.
        // Complete --> completed request,
        // next is a request for data an
        // error implies an errored request.
        handle = forward(operation).subscribe({
          complete: observer.complete.bind(observer),
          error: observer.error.bind(observer),
          next: observer.next.bind(observer),
        });
      } catch (e) {
        observer.error.bind(observer);
      }
      return () => {
        handle?.unsubscribe();
      };
    })
);

const httpLink = createUploadLink({
  credentials: 'same-origin',
  uri: config.API_BASE_URL,
});

export const AppApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

  const getLanguage = useLanguageGetter();

  const apolloClient = React.useMemo(() => {
    if (isLoading) return undefined;

    const authLink = setContext(async (_, { headers }) => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({ cacheMode: true });

        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            lang: getLanguage(),
            ...(sessionStorage.getItem('impersonationId')
              ? { impersonation: sessionStorage.getItem('impersonationId') }
              : {}),
          },
        };
      }
      return {
        headers: {
          ...headers,
          lang: getLanguage(),
        },
      };
    });

    return new ApolloClient({
      link: from([authLink, logErrorLink, requestLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently, getLanguage, isAuthenticated, isLoading]);

  if (isLoading) return <LoadingPage />;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
