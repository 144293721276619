import React, { Suspense, useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import CheckServiceWorkerUpdateReady from './checkServiceWorkerUpdateReady';
import { ErrorBoundary, SuspenseLoadingIndicator } from './common';
import config from './config';
import CoviewScript from './coviewScript';
import { CustomerReferralProvider } from './global/customerReferral';
import { GlobalStateProvider } from './global/state';
import GlobalStyles, { theme } from './global/style';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// window.fetch polyfill for consistent x-browser and x-device behavior
import { Auth0Provider } from '@auth0/auth0-react';
import 'whatwg-fetch';
import { AuthProvider, useAuth } from './global/auth/newAuthProvider';
import { AppEntryPoint } from './refactor/appEntry';
import { RootLayoutBehindAuthentication } from './refactor/root/router';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import LoadingPage from './global/application/loadingPage';
import { LoginLikeLayout } from './modules/loginLikeLayout';
import { LandingPage } from './modules/loginLikeLayout/landing';
import { OnBoardingRegistration } from './modules/loginLikeLayout/registration';

import './global/i18n/initializeI18n';
import IntegrationLayout from './refactor/integrations';
import { integrationRoutesWithoutAuthentication } from './refactor/integrations/router';
import { ViewerLanguageProvider } from './modules/languagePicker/viewerLanguageProvider';
import { AppApolloProvider } from './global/network/apolloClient';
import { LanguageGetterProvider } from './modules/languagePicker/languageGetterProvider';

const {
  GOOGLE_TAG_MANAGER_ID,
  ENABLE_GTM,
  BASE_URL,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} = config;

if (ENABLE_GTM) {
  TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
}

const INTERCOM_APP_ID = config.INTERCOM_APP_ID;
const INTERCOM_SHOULD_INITIALIZE = config.INTERCOM_SHOULD_INITIALIZE;

const PublicAppAuthorizationGuard = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return children;
};

const OnBoardingRegistrationGuard = () => {
  const { login, user } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!searchParams.get('userId')) {
      login({
        authorizationParams: {
          screen_hint: 'signup',
          mode: 'signUp',
        }
      });
      return;
    }

    if (user) {
      navigate('/');
    }
  }, [login, navigate, searchParams, user]);

  return <Outlet />;
};

const AppAuthorizationGuard = () => {
  const { isAuthenticated } = useAuth0();
  const { login } = useAuth();

  if (!isAuthenticated) {
    login({
      appState: {
        signedInRedirect: `${window.location.pathname}${window.location.search}${window.location.hash}`,
      },
    });
    return null;
  }

  return <Outlet />;
};

const IntercomProviderWrapper = ({ children }) => {
  const { user } = useAuth();

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      shouldInitialize={INTERCOM_SHOULD_INITIALIZE}
      autoBoot
      autoBootProps={{ ...user?.intercom_user }}
    >
      {children}
    </IntercomProvider>
  );
};

const queryClient = new QueryClient();

const Auth0ProverWrapper = ({ children }) => {
  const authorizationParams = React.useMemo(() => ({
    redirect_uri: BASE_URL,
    audience: AUTH0_AUDIENCE,
  }), []);

  const navigate = useNavigate();

  const handleRedirectCallback = React.useCallback((appState) => {
    if (typeof appState === 'object' && appState !== null && typeof appState.signedInRedirect === 'string') {
      navigate(appState.signedInRedirect);
    }
  }, [navigate]);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={authorizationParams}
      onRedirectCallback={handleRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const I18nWrapper = ({ children }) => {
  const { ready } = useTranslation();

  if (!ready) {
    return <LoadingPage />;
  }

  return children;
};

const NavigateWithoutPrefix = ({ prefix }) => {
  const location = useLocation();
  const to = useMemo(
    () => ({
      pathname: location.pathname.substring(prefix.length),
      search: location.search,
      hash: location.hash
    }),
    [location, prefix.length]
  );
  return <Navigate to={to} />;
};

ReactDOM.render(
  <React.StrictMode>
    {process.env.NODE_ENV === 'production' ? <CoviewScript /> : null}
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <ErrorBoundary root>
        <BrowserRouter>
          <CheckServiceWorkerUpdateReady />
          <CookiesProvider>
            <QueryClientProvider client={queryClient}>
              <LanguageGetterProvider>
                <Auth0ProverWrapper>
                  <IntercomProviderWrapper>
                    <CustomerReferralProvider>
                      <AppApolloProvider>
                        <AuthProvider>
                          <ViewerLanguageProvider>
                            <HelmetProvider>
                              <GlobalStateProvider>
                                <Suspense
                                  fallback={<SuspenseLoadingIndicator root />}
                                >
                                  <I18nWrapper>
                                    <Routes>
                                      <Route
                                        path="/"
                                        element={<AppAuthorizationGuard />}
                                      >
                                        <Route path="/" element={<AppEntryPoint />}>
                                          {RootLayoutBehindAuthentication}
                                        </Route>
                                      </Route>
                                      <Route
                                        path="/onboard"
                                        element={<LoginLikeLayout />}
                                      >
                                        <Route
                                          path="invite"
                                          element={
                                            <PublicAppAuthorizationGuard>
                                              <LandingPage />
                                            </PublicAppAuthorizationGuard>
                                          }
                                        />
                                        <Route
                                          path="registration"
                                          element={<OnBoardingRegistrationGuard />}
                                        >
                                          <Route
                                            index
                                            element={<OnBoardingRegistration />}
                                          />
                                        </Route>
                                      </Route>
                                      <Route
                                        path="/integrations"
                                        element={<IntegrationLayout />}
                                      >
                                        {integrationRoutesWithoutAuthentication}
                                      </Route>
                                      {/* Legacy support. */}
                                      <Route
                                        path="login"
                                        element={<Navigate to="/" />}
                                      />
                                      <Route
                                        path="register"
                                        element={<Navigate to="/onboard/registration" />}
                                      />
                                      {/* Deprecated /dashboard routes. */}
                                      <Route
                                        path="/dashboard/*"
                                        element={<NavigateWithoutPrefix prefix="/dashboard" />}
                                      />
                                    </Routes>
                                  </I18nWrapper>
                                </Suspense>
                              </GlobalStateProvider>
                            </HelmetProvider>
                          </ViewerLanguageProvider>
                        </AuthProvider>
                      </AppApolloProvider>
                    </CustomerReferralProvider>
                  </IntercomProviderWrapper>
                </Auth0ProverWrapper>
              </LanguageGetterProvider>
            </QueryClientProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.swUpdateReady = true;
    console.log(
      'Skip waiting for new content to be used, reload on route change is activated.'
    );
  },
});
